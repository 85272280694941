import React, { useEffect } from 'react';
import PromptPlaceholder from './UI/PromptPlaceholder';

import Thumb from '../images/Loading.svg';
import { useHistory } from 'react-router-dom';

function Loading({ checkStatus }) {
  const history = useHistory();

  useEffect(() => {
    checkStatus(history.push);
  }, []);

  return (
    <PromptPlaceholder
      heading="Loading Payment"
      icon={Thumb}
      className="Loading"
    >
      Please wait while we redirect you to your payment page
    </PromptPlaceholder>
  );
}

export default Loading;
