import React, { useEffect } from 'react';
import PromptPlaceholder from './UI/PromptPlaceholder';

import Thumb from '../images/Success.svg';
import { exitApp, parseQueryString } from '../util';

function Success() {
  const val = parseQueryString(window.location.search);
  const { courseName } = val;

  useEffect(() => {
    setTimeout(() => {
      exitApp();
    }, 2500);
  }, []);

  return (
    <PromptPlaceholder
      className="Success"
      heading="Congratulations!"
      icon={Thumb}
    >
      You have successfully purchased
      <br />
      <b>“{courseName}”</b>
      <br />
      Start learning now.
    </PromptPlaceholder>
  );
}

export default Success;
