export const parseQueryString = (str) => {
  str = decodeURIComponent(str.replace(/^\?/, ''));
  let obj = {};
  if (str.indexOf('=') !== -1) {
    str.split('&').forEach((query) => {
      // if (isNaN(query.split("=")[1])) {
      // 	obj[query.split("=")[0]] = query.split("=")[1];
      // } else {
      // 	obj[query.split("=")[0]] = Number(query.split("=")[1]);
      // }
      obj[query.split('=')[0]] = query.split('=')[1];
    });
  } else {
    return {};
  }
  return obj;
};

export const exitApp = () => {
  window?.mobile?.setTestState(`RELOAD_NATIVE_SCREEN`);
  window?.webkit?.messageHandlers?.stateEvents?.postMessage(
    'RELOAD_NATIVE_SCREEN'
  );
};
