import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import paymentsApiCall from './apiCall/service';
import { exitApp, parseQueryString } from './util';

import Loading from './components/Loading';
import Success from './components/Success';
import Failure from './components/Failure';
import Pending from './components/Pending';

import './App.css';

export default function App() {
  const [loopCounter, setLoopCounter] = useState(0);
  let val = parseQueryString(window.location.search);

  const redirectToPrompt = (redirectEvent, route) => {
    setLoopCounter(loopCounter + 1);
    redirectEvent(`/${route}${window.location.search}`);
  };

  const checkStatus = (redirectEvent) => {
    if (loopCounter < 4) {
      paymentsApiCall(val, (route) => redirectToPrompt(redirectEvent, route));
    } else {
      exitApp();
    }
  };

  return (
    <Router>
      <Switch>
        <Route path="/success" component={() => <Success />} />
        <Route path="/failure" component={() => <Failure />} />

        <Route path="/pending">
          <Pending
            val={val}
            loopCounter={loopCounter}
            checkStatus={checkStatus}
          />
        </Route>
        <Route path="/">
          <Loading checkStatus={checkStatus} />
        </Route>
      </Switch>
    </Router>
  );
}
