import PromptPlaceholder from './UI/PromptPlaceholder';
import { exitApp } from '../util';

import Thumb from '../images/Failure.svg';

function Failure() {
  return (
    <PromptPlaceholder
      heading="Payment failed!"
      icon={Thumb}
      className="Failure"
      button="Retry Payments"
      onClick={exitApp}
    >
      Your payment for this course could not be completed. Please retry again.
      Don’t worry in case money has been deducted from your account. They are
      safe.
    </PromptPlaceholder>
  );
}

export default Failure;
