// const baseUrl = 'https://api.staging.classplus.co';
const baseUrl = "https://api.classplusapp.com";
const urlPrefix = "v2/course/cart/escrowStatus";

const apiConfig = {
	url: ({ orderId, escrowId }) =>
		`${baseUrl}/${urlPrefix}/${orderId}/${escrowId}`,
	config: ({ token, region }) => {
		return {
			headers: {
				"x-access-token": token,
				region,
			},
		};
	},
};

export default apiConfig;
