import React, { useEffect, useRef } from 'react';
import PromptPlaceholder from './UI/PromptPlaceholder';

import Thumb from '../images/Pending.svg';
import { useHistory } from 'react-router-dom';

function Pending({ val, loopCounter, checkStatus }) {
  const history = useHistory();
  const timer = useRef(null);

  useEffect(() => {
    timer.current = setTimeout(() => {
      console.log('Hello, World!');
      checkStatus(history.push);
    }, 3000);

    return () => {
      clearTimeout(timer.current);
    };
  }, [loopCounter]);

  return (
    <PromptPlaceholder
      heading="Payment Processing"
      icon={Thumb}
      className="Pending"
    >
      Your payment is currently under processing. Please wait for sometime.
    </PromptPlaceholder>
  );
}

export default Pending;
